import React, { ReactNode } from 'react';
import { Box, BoxProps } from '@cp/ui/components';
import { CssTokens as GlobalTokens, styled } from '@cp/ui/theme';
import { cssToken } from '@cp/ui/utils';
import { Typography } from '@cp/ds/components/typography';
import { RatingBar } from '@cp/ds/components/ratingBar';

const name = 'ReviewCard';
export const CssTokens = {
  divider: cssToken(name, 'divider'),
  headerText: cssToken(name, ['header', 'text']),
};

const Root = styled(Box, { name, slot: 'root' })(({ theme }) => ({
  [CssTokens.divider]: theme.palette.divider,
  [CssTokens.headerText]: GlobalTokens.color.text.accent(theme.palette.brand.main),
  border: '1px solid',
  borderRadius: '12px',
  display: 'flex',
  flexDirection: 'column',
  gap: GlobalTokens.dimension.gap('8px'),
  borderColor: GlobalTokens.color.text.accent(theme.palette.brand.main),
  color: GlobalTokens.color.text.default(),
  padding: '20px',
  boxShadow: '0px 2.17px 3.25px 0px rgba(0, 0, 0, 0.1)',
}));
const Header = styled('header', { name: 'ReviewCard', slot: 'header' })({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  color: CssTokens.headerText(),
});
const Body = styled(Box, { name: 'ReviewCard', slot: 'body' })({});
const Footer = styled('footer', { name: 'ReviewCard', slot: 'footer' })({
  borderTop: '1px solid',
  borderColor: CssTokens.divider(),
  paddingTop: '20px',
});

export interface ReviewCardProps extends BoxProps {
  rating: number;
  header?: ReactNode;
  footer?: ReactNode;
}
export const ReviewCard: React.FC<ReviewCardProps> = ({ header, rating, footer, children, ...props }) => {
  return (
    <Root {...props}>
      <Header>
        <Typography variant="h3">{header}</Typography>
        <RatingBar iconSize={1.5} value={rating} />
      </Header>
      <Body>{children}</Body>
      <Footer>{footer}</Footer>
    </Root>
  );
};
